import Inputmask from 'inputmask'
import { useState, useEffect, useCallback, useMemo } from 'preact/hooks'
import CheckedImage from 'portals/check.svg'
import clsx from 'clsx'
import sortBy from 'lib/utils/sort-by'
import { useExpandMacros, InnerHTMLHelper, isPresent, presence, useVariable } from 'landing_pages/common/utils'

function ProgramButtons({leadTargets, getDegreeProgram, selectedLeadTarget, setSelectedLeadTarget}) {
  return (
    <div className="program-button-container">
        {leadTargets.map((leadTarget, index) => {
          return (
              <div key={index} className={clsx('program-button', { selected: selectedLeadTarget === leadTarget })}
              onClick={_event => setSelectedLeadTarget(leadTarget)}>
                <div className="button-square">
                  {getDegreeProgram(leadTarget)?.name}
                </div>
                <div className="button-circle">
                  <img src={CheckedImage}/>
                </div>
              </div>
            )
        })}
    </div>
  )
}

function ProgramList({leadTargets, getDegreeProgram, selectedLeadTarget, setSelectedLeadTarget}) {
  return (
    <select onChange={event => setSelectedLeadTarget(leadTargets[event.target.value])}>
      {leadTargets.map((leadTarget, index) => (
        <option
          key={index}
          value={index}
          selected={leadTarget === selectedLeadTarget}
        >
          {getDegreeProgram(leadTarget)?.name}
        </option>
      ))}
    </select>
  )
}

export default function Result({result, onSkip, onSubmit, pending = false, showSkip, firstName, phone, variables = {}}) {
  const [hasTcpa, setHasTcpa] = useState(false)

  const getDegreeProgram = useCallback(leadTarget => (
    !leadTarget ? null : result.programGroups.flatMap(programGroup => programGroup.degreePrograms).find(degreeProgram => String(degreeProgram.id) === String(leadTarget.degreeProgramId))
  ), [result])

  const [selectedLeadTarget, setSelectedLeadTarget] = useState(result.leadTargets[0])
  const needsTcpa = result.tcpaDisclosure && !result.implicitTcpa
  const isDisabled = pending || (needsTcpa && !hasTcpa)
  const sortedLeadTargets = useMemo(() => sortBy(result.leadTargets, leadTarget => getDegreeProgram(leadTarget)?.name), [result, getDegreeProgram])

  useEffect(() => {
    setSelectedLeadTarget(result.leadTargets[0])
    setHasTcpa(false)
  }, [result])

  const expandMacros = useExpandMacros(result)
  const variable = useVariable(result, variables)

  const headerText = variable('portal_result_header_text', 'We recommend checking out {school.name}!')
  const submitButtonText = variable('portal_result_submit_button_text', 'SELECT')
  const subHeaderText = variable('portal_result_sub_header_text', null)

  const heading = [
    variable('portal_result_show_name', null) && presence(firstName),
    expandMacros(headerText).replace(/^[a-z]/i, c => c.toLowerCase())
  ].filter(Boolean).join(', ').replace(/^[a-z]/, c => c.toUpperCase())

  const pleaseContinue = variable('portal_result_please_continue', 'Please continue:')
  const bulletPoints = variable('portal_result_bullet_points', [
    'To find out if financial aid programs are available',
    'To learn more about the program',
    'To speak with an enrollment advisor about this opportunity',
  ])

  const variant = variables['showB'] ? 'b' : 'a'
  const Programs = variant === 'b' ? ProgramButtons : ProgramList

  return (
    <div className={variant === 'b' ? 'result-b' : 'result' }>
      <div className="left">
        <h1>{heading}</h1>
        {variant !== 'b' && (
          <div className="logo mobile-only">
            <img src={result.campus.logoUrl || result.logoUrl} />
          </div>
        )}
        {subHeaderText && (
          <p>{expandMacros(subHeaderText)}</p>
        )}
        {isPresent(pleaseContinue) && (
          <p className="please-continue">
            {pleaseContinue}
          </p>
        )}
        {bulletPoints.length !== 0 && (
          <ul>
            {bulletPoints.map((bulletPoint, index) => (
              <li key={index}>{bulletPoint}</li>
            ))}
          </ul>
        )}
      </div>

      <div className="right">
        <div className="box">
          <div className={clsx('logo', { 'tablet-and-desktop-only': variant !== 'b' })}>
            <img src={result.campus.logoUrl || result.logoUrl} />
          </div>

          <Programs
            leadTargets={sortedLeadTargets}
            getDegreeProgram={getDegreeProgram}
            selectedLeadTarget={selectedLeadTarget}
            setSelectedLeadTarget={setSelectedLeadTarget}
          />

          {result.tcpaDisclosure && (
            <>
              <p className="tcpa" data-tf-element-role={'consent-language'}>
                <label className="tcpa_disclosure">
                  {needsTcpa && (
                    // @ts-ignore
                    <input type="checkbox" name="tcpa" value="true" checked={hasTcpa} onClick={event => setHasTcpa(event.target.checked)} data-tf-element-role={'consent-opt-in'} />
                  )}
                  <InnerHTMLHelper html={result.tcpaDisclosure} />
                </label>
              </p>
              {phone && (
                <p className="tcpa">Phone number provided: {Inputmask.format(phone, { mask: '(299) 299-9999' })}.</p>
              )}
            </>
          )}
          <div>
            <button
              type={onSubmit ? 'button' : 'submit'}
              onClick={() => onSubmit?.({
                program_id: selectedLeadTarget?.degreeProgramId,
                campaign_id: selectedLeadTarget?.clientCampaignId
              })}
              className={`cta-button submit-button disable-on-submit ${(isDisabled && !pending) ? "no-disabled-text" : ""}`}
              disabled={isDisabled}
              data-tf-element-role="submit"
            >
              <span className="enabled">
                {submitButtonText}
              </span>
              <span className="disabled">
                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                Please wait...
              </span>
            </button>
          </div>
          {showSkip && (
            <div>
              <button type="button" className="skip-button" onClick={onSkip}>Not interested</button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
